import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Layout2 from '../../components/layout2'
import Seo2 from '../../components/seo2'
import { blogTitle, h2 } from '../../components/layout.module.css'

const BlogPage = ({ data }) => {
  return (
    <Layout2 pageTitle="Blog Posts">
      {
        data.allMdx.nodes.map((node) => (
          <article key={node.id}>
            <h2>
              <Link className={blogTitle} to={`/blog/${node.frontmatter.slug}`}>
                {node.frontmatter.title}
              </Link>
              </h2>
            <p>Posted: {node.frontmatter.date}</p>
            <p>{node.excerpt}</p>
          </article>
        ))
      }
    </Layout2>
  )
}

export const query = graphql`
    query {
        allMdx(sort: {fields: frontmatter___date, order: DESC}) {
            nodes {
              frontmatter {
                title
                slug
                date(formatString: "MMMM D, YYYY")
              }

              id
              excerpt
              parent {
                ... on File {
                  modifiedTime(formatString: "MMMM D, YYYY")
                }
              }
            }
          }
        }`

export const Head = () => <Seo2 title="My Blog Posts" />

export default BlogPage